import React from "react";


function ProjectImgWrapper({children}) {

  return (
    <div className="ProjectImgWrapper">
        {children}
    </div>
  );
}

export default ProjectImgWrapper;
