import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../components/Website/ProjectImagesSwiper";
import ProjectImgWrapper from "../../components/Common/ImgWrapper";

const HealthCoach = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Health coach"
      description="Health coach"
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title mb--10">
                    <h2>Health coach</h2>
                  </div>
                  <ServicePellet service="UI & UX" />

                  <p class="mb--20 d-md-block d-none">
                    Mobile app that allows you to record your health values very
                    easily to monitor them.
                    <br />
                    Health data management suitable for current times. Gone are
                    the days when you had to take blood pressure or weight
                    changes daily. In addition, you will also be able to record
                    your daily physical activity habits and your sleep rhythm
                    very easily.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[6]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <p className="my-4  d-md-none d-block">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere
              vehicula consequat id consequat tempus. Potenti et rhoncus morbi
              amet at volutpat. Vivamus neque sed tellus pulvinar. Mauris
              egestas euismod a iaculis cras non. Nisl lacinia nulla mauris ut.
              Donec vitae, dui id mattis molestie morbi hendrerit egestas sed.
              Massa venenatis, donec consectetur enim eget turpis aliquam
              lectus.
            </p>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[0]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[1]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[2]} />
                </ProjectImgWrapper>
              </Col>
            </Row>
            <ProjectImagesSwiper
              imagesList={imagesData.filter((img, id) => id !== 6)}
            />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[3]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[4]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[5]} />
                </ProjectImgWrapper>
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="iberdrola" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{
              name: "Medicamentos à porta",
              link: "/work/medicamentos",
            }}
            prevProject={{
              name: "Houses To Share",
              link: "/work/housestoshare",
            }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default HealthCoach;

export const HealthCoachQuery = graphql`
  query HealthCoachPhotos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/healthcoach/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
